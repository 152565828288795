<template>
  <div>
    <div class="api-subscription-title has-text-grey-dark has-text-centered">Your Payment Details</div>
    <Spinner v-show="showInProgressSection" message="Loading your payment details. Please wait..."/>
    <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    <div v-show="(!charges || charges.length<1) && !doodleForm.inProgress && !nextPaymentDate && doodleForm.completed" class="has-text-grey-dark has-text-weight-bold">
      No Payment Details Found.
    </div>
    <div class="has-text-centered" v-if="this.doodleForm.completed">
      <div class="api-subscription-detail">
        <div class="has-text-weight-bold has-text-grey-dark">Next Payment Date</div>
        <div class="has-text-weight-bold has-text-danger">{{nextPaymentDate}}</div>
      </div>
      <div class="level">
        <div class="level-item">
          <table class="table is-striped is-hoverable has-background-white-ter">
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Outcome</th>
            </tr>
            <tr v-for="(charge,index) in charges" v-bind:key="index">
              <td>{{getDate(charge.created)}}</td>
              <td>${{charge.amount/100}}</td>
              <td>{{charge.outcome}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import Spinner from '../Utils/Spinner'
import DoodleMessage from '../Utils/DoodleMessage'
import moment from 'moment'

export default {
  name: 'apiPayments',
  components: { DoodleMessage, Spinner },
  mixins: [formMixin],
  data: function () {
    return {
      nextPaymentDate: null,
      charges: null
    }
  },
  mounted () {
    this.executeAPIWitoutValiation({
      url: '/api/compiler-api/paymentDetail',
      successAction: (data) => {
        this.nextPaymentDate = moment(data.nextPaymentDate).format('DD-MM-YYYY')
        this.charges = data.charges
      },
      markCompleted: true,
      form: this.doodleForm,
      jdaCategory: window.jda.CATEGORY.API,
      jdaEvent: 'payment-detail',
      jdaLabel: ''
    })
  },
  methods: {
    getDate (dte) {
      return moment(dte).format('DD-MM-YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
  .api-subscription-title {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1em;
  }

  .api-subscription-detail {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.75em;
  }

  .api-subscription-sub-detail {
    font-weight: bold;
    font-size: 0.8em;
  }
</style>
